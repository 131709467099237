import factory from './icon';
import Svg8x8 from './glyphs/CircleUnfilled_8x8.svg';
import Svg12x12 from './glyphs/CircleUnfilled_12x12.svg';
import SvgFilled8x8 from './glyphs/CircleFilled_8x8.svg';
import SvgFilled12x12 from './glyphs/CircleFilled_12x12.svg';

export const CircleFilled8x8 = factory(SvgFilled8x8, 8, 8);
export const CircleFilled12x12 = factory(SvgFilled12x12, 12, 12);
export const CircleUnfilled8x8 = factory(Svg8x8, 8, 8);
export const CircleUnfilled12x12 = factory(Svg12x12, 12, 12);

export const CircleFilled8x8Raw = SvgFilled8x8;
export const CircleUnfilled8x8Raw = Svg8x8;
