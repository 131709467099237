import React, { useState } from 'react';
import { styled, StyledProps } from '@glitz/react';
import { replaceState, translate } from '@polarnopyret/scope';
import Image, { Preset as ImagePreset, Ratio as ImageRatio } from 'Shared/Image/Ratio';
import { productClickInList } from 'TrackingInformation';
import { ProductCardWrapper } from './shared';
import { isCompact } from 'Shared/Viewport';
import connect from 'Shared/connect';
import { ButtonHeight, ButtonTheme, SolidButton } from 'Shared/TextButtons';
import { Plus12x12 } from 'Shared/Icons/Plus';
import { Check16x16 } from 'Shared/Icons/Check';
import { PageType } from 'Shared/State';
import { addToCart } from 'Cart/action-creators';
import { addCartItem as checkoutAddToCart } from 'Checkout/action-creators';
import { toggleWishlistItem as toggleWishlistData } from 'WishList/action-creators';
import { wishListUrl } from 'Shared/known-urls';
import ProductListItemViewModel from 'Product/ProductListing/ProductListItemViewModel.type';
import { SetDisplayedQuickShopProducts } from 'Shared/uiReducer';
import { colorFromTag, FormatString } from 'Shared/utils';
import { Favourite20x20, FavouriteFull20x20, Favourite24x24, FavouriteFull24x24 } from 'Shared/Icons/Favorite';
import MiniWishListViewModel from 'WishList/MiniWishListViewModel.type';
import WishListUpdateResultType from 'WishList/WishListUpdateResult.type';
import SitePages from 'AppShell/Models/SitePages.type';
import { openAccountBox } from 'SiteLayout/AccountBox/action-creators';
import Price from 'Pricing/Price';
import Link from 'Shared/Link';
import { ResponsiveLineHeight, ResponsiveSize, TextBlock, Text } from 'Shared/SharedComponents/atoms/Typography';
import FeedbackButton from 'Shared/Button/Feedback';
import CheckoutUpdateResultType from '/Checkout/Pages/Checkout/CheckoutUpdateResult.type';
import currentPageIsCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';
import currentPageIsWishlist from 'WishList/current-page-is-wishlist';
import { HideCartModal } from 'Shared/uiReducer';
import * as style from 'Shared/Style';

const SHOW_ADD_TO_CART = 'SHOW_ADD_TO_CART';
const SHOW_SIZES = 'SHOW_SIZES';
const SHOW_ADDED_TO_CART = 'SHOW_ADDED_TO_CART';
const imageRatio = ImageRatio.ThreeToFour;

export const ANIMATION_DURATION = 250;
export const HEIGHT = 120;
export const COMPACT_HEIGHT = 64;

export type RequiredPropType = {
  product: ProductListItemViewModel;
  className?: string;
  noRating?: boolean;
  listIndex: number;
  smallIcons?: boolean;
  showWeatherPro?: boolean;
  quickAddToCart?: boolean;
};

type ConnectedPropType = {
  currentBreakpoint: number;
  currentPage: PageType;
  displayCartModal: boolean;
  shopAtStore: boolean;
  shopAtStoreLabel: string[];
  pages: SitePages;
  loggedIn: boolean;
  wishlist: MiniWishListViewModel;
  culture: string;
  currency: string;
};

type AddToCartType = {
  type: string;
  sizeName: string;
  sizeCode: string;
};

export type PropType = RequiredPropType;

export const ProductLink = styled(Link, {
  color: 'inherit',
  textDecoration: 'none',
});

const BoldUpperCaseSpan = styled.span({
  fontWeight: 500,
  textTransform: 'uppercase',
});

const TagWrap = styled.div({
  display: 'flex',
  gap: '6px',
  minHeight: '12px'
})

const Tag = styled(BoldUpperCaseSpan, {
  fontSize: '12px',
  lineHeight: '100%',
})

const ProductCardInner = styled.div({
  position: 'relative',
})

const ProductMarkingsWrap = styled.div({
  position: 'absolute',
  top: '12px',
  left: '12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  ['@media ' + style.mediaMinLarge]: {
    top: '16px',
    left: '16px',
    fontSize: '14px',
    gap: '8px'
  },
})

const ProductMarking = styled.span({
  fontSize: '12px',
  fontWeight: 500,
  textTransform: 'uppercase',
  lineHeight: '100%',
  color: style.colors.monochrome.black,
  pointerEvents: 'none',
  ['@media ' + style.mediaMinLarge]: {
    fontSize: '14px'
  },
})

const FavoriteWrap = styled.div({
  position: 'absolute',
  pointerEvents: 'none',
  ['@media ' + style.mediaUpToLarge]: {
    bottom: '12px',
    left: '12px',
  },
  ['@media ' + style.mediaMinLarge]: {
    top: '16px',
    right: '16px',
    opacity: 0,
  },
})

const FavoriteInner = styled.div({
  pointerEvents: 'all',
  ['@media ' + style.mediaUpToLarge]: {
    height: '20px',
  },
})

const ProductLinkWrap = styled.div({
  padding: { top: 8 },
  display: 'block',
  fontSize: 14,
  lineHeight: '100%',
  fontWeight: 'bold',
  color: style.colors.monochrome.black,
})

const ProductLinkWrapInner = styled.div({
  display: 'flex',
  ['@media ' + style.mediaUpToLarge]: {
    flexDirection: 'column',
    gap: '4px',
  },
})

const ProductLinkTextWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  ['@media ' + style.mediaMinLarge]: {
    flexGrow: 1,
  },
})

const ProductOnelineText = styled(TextBlock, {
  textTransform: 'initial',
  fontWeight: 450,
  minHeight: `calc(1.2 * 12)px`,
  ['@media ' + style.mediaMinLarge]: {
    fontWeight: 500,
    minHeight: `calc(1.3 * 14)px`,
  },
})

const QuickAddToCartWrap = styled.div({
  position: 'absolute',
  boxSizing: 'border-box',
  bottom: '16px',
  width: '100%',
  pointerEvents: 'none',
  ['@media ' + style.mediaMinLarge]: {
    bottom: '12px',
  },
})

const QuickAddToCartInner = styled.div({
  marginLeft: '12px',
  marginRight: '12px',
  ['@media ' + style.mediaMinLarge]: {
    marginLeft: '16px',
    marginRight: '16px',
  },
})

const Plus = styled(Plus12x12, {
  marginLeft: '6px',
})

const Check = styled(Check16x16, {
  marginLeft: '8px'
})

const SizeLabel = styled(BoldUpperCaseSpan, {
  fontStyle: 'normal',
  lineHeight: '100%',
  fontSize: '14px',
  letterSpacing: '0.01em',
  color: style.colors.monochrome.black,
  width: '100%',
  textAlign: 'left',
  marginBottom: '4px',
})

const AddSize = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
})

const SecondaryImage = styled(Image, {
  position: 'absolute',
  width: '100%',
  height: '100%',
  transform: 'translateY(-100%)',
  transition: {
    property: 'opacity',
    duration: '0.3s',
  },
  opacity: 0,
})

const CardOverlay = styled.div({
  backgroundColor: style.colors.rgbaFilters.blue,
  position: 'absolute',
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
})

const CardOverlayLabel = styled(Text, {
  color: style.colors.monochrome.white,
  textAlign: 'center',
  lineHeight: '110%',
  paddingLeft: '16px',
  paddingRight: '16px',
  ['@media ' + style.mediaMinLarge]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
})

const ProductCard = styled(
  connect(
    (state) => ({
      wishlist: state.wishlist,
      culture: state.appShellData.culture,
      currency: state.appShellData.market.currency,
      pages: state.appShellData.pages,
      shopAtStore: state.appShellData.market.shopAtStore,
      shopAtStoreLabel: state.appShellData.market.shopAtStoreLabel,
      loggedIn: state.currentUser?.isLoggedIn ?? false,
      currentBreakpoint: state.currentBreakpoint,
      currentPage: state.currentPage,
      displayCartModal: state.UI.displayCartModal,
    }),
    (dispatch) => ({
      toggleWishlistItem(isFavorited: boolean, code: string, colorProductCode: string, currentCategory: string) {
        return dispatch(toggleWishlistData(isFavorited, code, colorProductCode, currentCategory))
      },
      addToCart(
        currentPage: PageType,
        code: string,
        quantity: number,
        ticket: string,
        currentCategory: string,
        colorProductCode: string,
      ) {
        if (currentPageIsCheckout(currentPage)) {
          return dispatch(checkoutAddToCart(code, quantity, ticket, 'Kassasida', currentCategory, colorProductCode));
        } else {
          return dispatch(addToCart(code, quantity, ticket, 'Produktlista', currentCategory, colorProductCode, false, true));
        }
      },
      setDisplayedQuickShopProducts: (products?: ProductListItemViewModel[]) =>
        dispatch(SetDisplayedQuickShopProducts(products)),
      openAccountBox() {
        dispatch(openAccountBox());
      },
      hideCartModal: () => dispatch(HideCartModal()),
    }),
  )(
    (
      props: PropType & ConnectedPropType &
        StyledProps & {
          toggleWishlistItem: (
            isFavorited: boolean,
            code: string,
            colorProductCode: string,
            currentCategory: string,
          ) => Promise<WishListUpdateResultType>;
          addToCart: (
            currentPage: PageType,
            code: string,
            quantity: number,
            ticket: string,
            currentCategory: string,
            colorProductCode: string,
          ) => Promise<CheckoutUpdateResultType>;
          setDisplayedQuickShopProducts: (products?: ProductListItemViewModel[]) => void;
          openAccountBox: () => void;
          hideCartModal: () => void;
        },
    ) => {
      const {
        product,
        currency,
        culture,
        quickAddToCart,
        addToCart,
        setDisplayedQuickShopProducts,
        pages,
        toggleWishlistItem,
        openAccountBox,
        hideCartModal,
        loggedIn,
        wishlist,
        currentPage,
      } = props;

      const onTracking = () => {
        productClickInList(product, product.categoryName, props.listIndex + 1);
      };
      const isWishListPage = currentPageIsWishlist(currentPage);
      const [isFavorited, setFavourite] = useState(props.wishlist.items?.some((p) => product.code === p));
      const [quickAddStep, setQuickAddStep] = useState({ type: SHOW_ADD_TO_CART } as AddToCartType);

      const isMobile = isCompact(props.currentBreakpoint);
      const multipleImages = !isMobile && product.imageUrls?.length > 1;
      const Favorite = (isFavorited || isWishListPage) ? (isMobile ? FavouriteFull20x20 : FavouriteFull24x24) : (isMobile ? Favourite20x20 : Favourite24x24);
      const enabledImageHover = isWishListPage || (multipleImages || !isMobile);
      const displayCardOverlay = product.comingSoon || (props.shopAtStore && props.shopAtStoreLabel && product.outOfStock);
      const cardOverLayLabel = product.comingSoon ? [translate("/site/product/comingsoonlist")] : props.shopAtStoreLabel;

      const enabledQuickAdd = !displayCardOverlay && quickAddToCart;

      const addSizeToCart = async (code: string, name: string) => {
        const b = await addToCart(currentPage, code, 1, product.ticket, product.categoryName, product.code);
        if (b.success) {
          setQuickAddStep({ type: SHOW_ADDED_TO_CART, sizeCode: code, sizeName: name } as AddToCartType);
          setTimeout(() => {
            setQuickAddStep({ type: SHOW_ADD_TO_CART } as AddToCartType);
          }, 2000);
          return Promise.resolve();
        } else {
          return Promise.reject(null);
        }
      };

      const toggleWishlist = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>, isFavorited: boolean, code: string) => {
        const b = await toggleWishlistItem(isFavorited, code, product.code, product.categoryName);
        if (b?.statusCode === 200) {
          const value = !isFavorited;
          if (isWishListPage && !value) {
            replaceState(wishListUrl());
          } else {
            setFavourite(value);
          }
        }
      };

      const productTags = (
        <TagWrap>
          {product.tags?.map((tag, index) => {
            return (
              <Tag
                key={index}
                css={{
                  color: tag.color ?? colorFromTag(tag.code) ?? style.colors.monochrome.black,
                }}
              >
                {tag.tag}
              </Tag>
            );
          }
          )}
        </TagWrap>
      );

      const hoverProduct = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, flag: boolean) => {
        const opacity = flag ? '1' : '0';
        const selector = isWishListPage ? '.p-fav' : '.p-opac';
        const elems = event.currentTarget.querySelectorAll(selector);
        elems.forEach(el => {
          (el as HTMLElement).style.opacity = opacity;
        })
      }

      return (
        <ProductCardWrapper
          className="product-card"
          css={props.compose()}
          onMouseEnter={(e) => enabledImageHover ? hoverProduct(e, true) : null}
          onMouseLeave={(e) => enabledImageHover ? hoverProduct(e, false) : null}
        >
          <ProductCardInner>
            <ProductLink
              to={product.url}
              onPageLoaded={() => {
                setDisplayedQuickShopProducts(null);
              }}
              linkIdentifier={product.ticket}
              onClick={() => {
                onTracking();
                if (props.displayCartModal) {
                  hideCartModal();
                }
              }}
            >
              {displayCardOverlay && <CardOverlay>
                {cardOverLayLabel?.map((label, index) => (
                  <CardOverlayLabel key={index} fontSize={ResponsiveSize.D32_M16} upperCase>{label}</CardOverlayLabel>
                ))}
              </CardOverlay>}
              <Image
                src={{ url: product.imageUrls?.[0] }}
                title={product.displayName}
                alt={product.displayName}
                ratio={imageRatio}
                preset={ImagePreset.ListItem}
                breakPoint={isMobile ? 0 : 1}
              />
              {multipleImages &&
                <SecondaryImage
                  wrapperClassName='p-opac'
                  src={{ url: product.imageUrls?.[1] }}
                  title={product.displayName}
                  alt={product.displayName}
                  ratio={imageRatio}
                  preset={ImagePreset.ListItem}
                  breakPoint={isMobile ? 0 : 1}
                  wrapperStyle={{
                    transitionProperty: 'opacity',
                    transitionDuration: '0.3s',
                    opacity: 0,
                  }}
                />
              }
              {product.markings && (
                <ProductMarkingsWrap>
                  {product.markings?.map((marking, index) => (
                    <ProductMarking key={index}>{marking}</ProductMarking>
                  ))}
                </ProductMarkingsWrap>
              )}
            </ProductLink>
            {!enabledQuickAdd && (
              <FavoriteWrap className='p-opac p-fav'>
                <FavoriteInner
                  onClick={(e) => {
                    if (loggedIn) {
                      toggleWishlist(e,
                        isFavorited,
                        product.preSelectedSizeCode,
                      );
                    } else {
                      return openAccountBox();
                    }
                  }}
                >
                  <Favorite
                    css={{
                      pointerEvents: 'all',
                      color: 'black',
                      cursor: 'pointer',
                      fill: isFavorited ? 'black' : 'none'
                    }}
                  />
                </FavoriteInner>
              </FavoriteWrap>
            )}
            {enabledQuickAdd && (
              <QuickAddToCartWrap>
                <QuickAddToCartInner>
                  {quickAddStep.type === SHOW_ADD_TO_CART && (
                    <SolidButton
                      text={translate('/site/cart/add')}
                      fullWidth
                      onClick={(e) => {
                        setQuickAddStep({ type: SHOW_SIZES } as AddToCartType);
                      }}
                      height={ButtonHeight.Small}
                      css={{
                        pointerEvents: quickAddStep.type === SHOW_ADD_TO_CART ? 'all' : 'none',
                      }}
                      iconAfter={
                        <Plus />
                      }
                      theme={ButtonTheme.BlackToWhite}
                      display="block"
                    />
                  )}

                  {quickAddStep.type === SHOW_SIZES && (
                    <div>
                      <SizeLabel>
                        {translate('/site/cart/size')}
                      </SizeLabel>
                      <AddSize>
                        {product.sizes.map((size, index) => (
                          <FeedbackButton
                            onClick={() => addSizeToCart(size.code, size.sizeName)}
                            key={index}
                            text={size.sizeName}
                            height={ButtonHeight.Small}
                            disabled={size.centralStockAvailability < 1}
                            theme={ButtonTheme.TransparentBlack}
                            strikeThrough={size.centralStockAvailability < 1}
                            padding={{ x: 12 }}
                            css={{
                              pointerEvents:
                                size.centralStockAvailability > 0 && quickAddStep.type === SHOW_SIZES ? 'auto' : 'none',
                            }}
                            fontSize={12}
                          />
                        ))}
                      </AddSize>
                    </div>
                  )}

                  {quickAddStep.type === SHOW_ADDED_TO_CART && (
                    <div>
                      <SolidButton
                        text={FormatString(translate('/site/cart/added'), quickAddStep.sizeName)}
                        onClick={() => { }}
                        css={{ pointerEvents: 'none', }}
                        height={ButtonHeight.Small}
                        fullWidth
                        theme={ButtonTheme.TransparentBlack}
                        iconAfter={<Check />}
                      />
                    </div>
                  )}
                </QuickAddToCartInner>
              </QuickAddToCartWrap>
            )}
          </ProductCardInner>

          <ProductLink
            to={product.url}
            title={product.displayName}
            onPageLoaded={() => { setDisplayedQuickShopProducts(null); }}
            linkIdentifier={product.ticket}
            onClick={() => {
              onTracking();
              if (props.displayCartModal) {
                hideCartModal();
              }
            }}
          >
            <ProductLinkWrap>
              <ProductLinkWrapInner>
                <ProductLinkTextWrap>
                  <TextBlock fontSize={ResponsiveSize.D14_M14} lineHeight={ResponsiveLineHeight.D100_M100} upperCase>
                    {product.displayName}
                  </TextBlock>
                  <ProductOnelineText fontSize={ResponsiveSize.D14_M12} lineHeight={ResponsiveLineHeight.D130_M120}>
                    {product.oneLineText}
                  </ProductOnelineText>
                  {!isMobile && productTags}
                </ProductLinkTextWrap>
                <Price microData {...product.price} vertical={!isMobile} fontSize={14} fontSizePrevPrice={12} />
                {isMobile && productTags}
              </ProductLinkWrapInner>
            </ProductLinkWrap>
          </ProductLink>
        </ProductCardWrapper>
      );
    },
  ),
);

export default ProductCard;
