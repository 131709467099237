import factory from './icon';
import Svg16x16 from './glyphs/Heart_16x16.svg';
import Svg20x20 from './glyphs/Heart_20x20.svg';
import Svg24x24 from './glyphs/Heart_24x24.svg';

export const Favourite16x16 = factory(Svg16x16, 16, 16);
export const Favourite20x20 = factory(Svg20x20, 20, 20);
export const Favourite24x24 = factory(Svg24x24, 24, 24);

export const FavouriteFull16x16 = factory(Svg16x16, 16, 16, true);
export const FavouriteFull20x20 = factory(Svg20x20, 20, 20, true);
export const FavouriteFull24x24 = factory(Svg24x24, 24, 24, true);

