import { styled } from '@glitz/react';
import factory from './icon';
import Svg32x32 from './glyphs/ArrowCircleUp_32x32.svg';
import Svg40x40 from './glyphs/ArrowCircleUp_40x40.svg';
import Svg48x48 from './glyphs/ArrowCircleUp_48x48.svg';

export const Up32x32 = factory(Svg32x32, 32, 32);
export const Down32x32 = styled(Up32x32, {
  transform: 'rotate(180deg)',
});
export const Right32x32  = styled(Up32x32, {
  transform: 'rotate(90deg)',
});
export const Left32x32  = styled(Up32x32, {
  transform: 'rotate(270deg)',
});

export const Up40x40 = factory(Svg40x40, 40, 40);
export const Down40x40 = styled(Up40x40, {
  transform: 'rotate(180deg)',
});
export const Right40x40  = styled(Up40x40, {
  transform: 'rotate(90deg)',
});
export const Left40x40  = styled(Up40x40, {
  transform: 'rotate(270deg)',
});

export const Up48x48 = factory(Svg48x48, 48, 48);
export const Down48x48 = styled(Up48x48, {
  transform: 'rotate(180deg)',
});
export const Right48x48  = styled(Up48x48, {
  transform: 'rotate(90deg)',
});
export const Left48x48  = styled(Up48x48, {
  transform: 'rotate(270deg)',
});


export const RightUri =
  'data:image/svg+xml,' +
  encodeURIComponent(`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M24 29L29 24.5V23.5L24 19" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M18 24H29" stroke="white" stroke-width="2" stroke-linejoin="bevel"/>
<rect x="47" y="1" width="46" height="46" rx="23" transform="rotate(90 47 1)" stroke="white" stroke-width="2"/>
</svg>
`);

export const LeftUri = 'data:image/svg+xml,' +
  encodeURIComponent(`<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: rotate(180deg)">
<path d="M24 29L29 24.5V23.5L24 19" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M18 24H29" stroke="white" stroke-width="2" stroke-linejoin="bevel"/>
<rect x="47" y="1" width="46" height="46" rx="23" transform="rotate(90 47 1)" stroke="white" stroke-width="2"/>
</svg>
`);
