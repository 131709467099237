import { styled } from '@glitz/react';
import factory from './icon';
import Svg16x32 from './glyphs/ArrowSwirlUp_16x32.svg';
import Svg24x40 from './glyphs/ArrowSwirlUp_24x40.svg';
import Svg32x64 from './glyphs/ArrowSwirlUp_32x64.svg';
import Svg40x80 from './glyphs/ArrowSwirlUp_40x80.svg';

export const Up16x32 = factory(Svg16x32, 12, 32);
export const Down16x32 = styled(Up16x32, {
  transform: 'rotate(180deg)',
});
export const Right16x32  = styled(Up16x32, {
  transform: 'rotate(90deg)',
});
export const Left16x32  = styled(Up16x32, {
  transform: 'rotate(270deg)',
});

export const Up24x40 = factory(Svg24x40, 24, 40);
export const Down24x40 = styled(Up24x40, {
  transform: 'rotate(180deg)',
});
export const Right24x40  = styled(Up24x40, {
  transform: 'rotate(90deg)',
});
export const Left24x40  = styled(Up24x40, {
  transform: 'rotate(270deg)',
});

export const Up32x64 = factory(Svg32x64, 32, 64);
export const Down32x64 = styled(Up32x64, {
  transform: 'rotate(180deg)',
});
export const Right32x64  = styled(Up32x64, {
  transform: 'rotate(90deg)',
});
export const Left32x64  = styled(Up32x64, {
  transform: 'rotate(270deg)',
});

export const Up40x80 = factory(Svg40x80, 40, 80);
export const Down40x80 = styled(Up40x80, {
  transform: 'rotate(180deg)',
});
export const Right40x80  = styled(Up40x80, {
  transform: 'rotate(90deg)',
});
export const Left40x80  = styled(Up40x80, {
  transform: 'rotate(270deg)',
});
