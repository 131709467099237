import { styled, StyledProps } from '@glitz/react';
import React, { useState } from 'react';
import connect from 'Shared/connect';
import Link from 'Shared/Generic/A';
import Image, { Preset as ImagePreset, Ratio as ImageRatio } from 'Shared/Image/Ratio';
import { productClickInList } from 'TrackingInformation';
import Viewport from 'Shared/Viewport';
import { ProductCardWrapper } from 'Product/ProductCard/shared';
import ProductListItemViewModel from 'Product/ProductListing/ProductListItemViewModel.type';
import { colorFromTag } from 'Shared/utils';
import Price from 'Pricing/Price';
import * as style from 'Shared/Style';

const USE_HOVER = false;

const ProductCardBody = styled(Link, {
  color: 'inherit',
  textDecoration: 'none',
  position: 'relative',
  backgroundColor: style.colors.monochrome.extraLightGrey,
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '8px',
});

const ProductCardImageWrap = styled.div({
  userSelect: 'none', minWidth: '96px', borderRadius: '8px', overflow: 'hidden'
})

const ProductCardDataWrap = styled.div({
  padding: { left: '8px', top: '8px', right: '12px', bottom: '8px' }
})

const ProductCardHoverImage = styled(Image, {
  position: 'absolute',
  height: 'auto',
  right: '0',
  left: '0',
  transform: 'translateY(-100%)',
  opacity: 0,
  transition: {
    property: 'opacity',
    duration: '0.3s',
  },
})

const ProductCardDataTop = styled.div({
  fontSize: '14px',
  lineHeight: '100%',
  fontWeight: 'bold',
  color: style.colors.monochrome.black,
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  ['@media ' + style.mediaMinLarge]: {
    flexDirection: 'row'
  },
})

const ProductCardNameWrap = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  textTransform: 'uppercase',
  flexDirection: 'column',
  ['@media ' + style.mediaMinLarge]: {
    flexDirection: 'row'
  },
})

const ProductCardName = styled.span({
  marginBottom: '4px'
})

const ProductCardOnelineWrap = styled.div({
  marginBottom: '4px'
})

const ProductCardOnelineDesktop = styled.span({
  lineHeight: '130%',
  textTransform: 'initial',
  fontWeight: 500
})

const ProductCardOnelineMobile = styled.span({
  marginBottom: '4px',
  fontSize: '12px',
  lineHeight: '120%',
  textTransform: 'initial',
  fontWeight: 500,
})

const ProductCardDataBottom = styled.div({
  lineHeight: '100%',
  marginBottom: '8px',
  minHeight: '12px',
  ['@media ' + style.mediaMinLarge]: {
    minHeight: '14px',
  },
})

const ProductCardTag = styled.span({
  lineHeight: '100%',
  textTransform: 'uppercase',
  fontWeight: 500,
  marginRight: '6px',
  fontSize: '12px',
  ['@media ' + style.mediaMinLarge]: {
    fontSize: '14px',
    marginRight: '8px'
  },
})

const QuickSearchResultProductCard = styled(
  connect((state) => ({
    culture: state.appShellData.culture,
    currency: state.appShellData.market.currency,
  }))(
    (
      props: StyledProps & {
        culture: string;
        currency: string;
        product: ProductListItemViewModel;
        listIndex: number;
      },
    ) => {
      const { product, currency, culture, ...restProps } = props;
      const testDataId = !product.outOfStock && { 'data-test': 'test-product-card-with-stock' };
      const onTracking = () => {
        productClickInList(product, product.categoryName, props.listIndex + 1);
      };

      const [isHovered, setIsHovered] = USE_HOVER ? useState(false) : [false, (value: any) => { }];

      return (
        <Viewport>
          {(isCompact: boolean) => (
            <ProductCardWrapper
              className="sg-product-card"
              css={props.compose()}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <ProductCardBody
                draggable="false"
                to={product.url}
                title={product.displayName}
                linkIdentifier={product.ticket}
                onClick={onTracking}
                {...testDataId}
              >
                <ProductCardImageWrap>
                  <Image
                    src={{ url: product.imageUrls?.[0] }}
                    title={product.displayName}
                    alt={product.displayName}
                    ratio={ImageRatio.TwoToThree}
                    preset={ImagePreset.ListItem}
                  />
                  {product.imageUrls?.length > 1 && (
                    <ProductCardHoverImage
                      src={{ url: product.imageUrls?.[1] }}
                      title={product.displayName}
                      alt={product.displayName}
                      ratio={ImageRatio.TwoToThree}
                      preset={ImagePreset.ListItem}
                      css={{
                        ...(isHovered && {
                          opacity: 1,
                        })
                      }}
                    />
                  )}
                </ProductCardImageWrap>
                <ProductCardDataWrap>
                  <ProductCardDataTop>
                    <div>
                      <ProductCardNameWrap>
                        <ProductCardName>{product.displayName}</ProductCardName>
                        {isCompact && (
                          <ProductCardOnelineMobile>
                            {product.oneLineText}
                          </ProductCardOnelineMobile>
                        )}
                      </ProductCardNameWrap>
                      {!isCompact && (
                        <ProductCardOnelineWrap>
                          <ProductCardOnelineDesktop>
                            {product.oneLineText}
                          </ProductCardOnelineDesktop>
                        </ProductCardOnelineWrap>
                      )}
                    </div>
                  </ProductCardDataTop>
                  <ProductCardDataBottom>
                    {product.tags?.map((tag, index) => (
                      <ProductCardTag
                        key={index}
                        css={{
                          color: tag.color ?? colorFromTag(tag.code) ?? style.colors.monochrome.black,
                        }}
                      >
                        {tag.tag}
                      </ProductCardTag>
                    ))}
                  </ProductCardDataBottom>
                  <Price {...product.price} fontSize={14} />
                </ProductCardDataWrap>
              </ProductCardBody>
            </ProductCardWrapper>
          )}
        </Viewport>
      );
    },
  ),
);

export default QuickSearchResultProductCard;