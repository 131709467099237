import React from 'react';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import * as style from 'Shared/Style';
import Button, { Appearance as ButtonAppearance } from 'Shared/Button';
import { BorderStyle } from 'Shared/Style';
export enum Appearance {
  Bare,
}

const focusColor = style.colors.monochrome.black;
const blurColor = style.colors.monochrome.lightGrey;
const errorColor = style.colors.negativeColors.negative;

type PlaceholderPropType = {
  invalid?: boolean;
  required?: boolean;
};

export const Message = styled.div({
  textAlign: 'center',
});

export const ErrorMessage = styled(styled.Label, {
  color: errorColor,
  userSelect: 'none',
  display: 'inherit',
  textAlign: 'left',
  position: 'absolute',
  top: '2px',
  left: '17px',
  width: 'calc(100% - 48px)',
  fontSize: '12px',
  zIndex: 2,
});

export const Label: React.StatelessComponent<PlaceholderPropType> = (props) => {
  return (
    <ErrorMessage
      css={{
        color: style.colors.monochrome.darkGrey,
        ...(props.invalid && {
          color: errorColor,
          backgroundColor: style.colors.monochrome.white,
        }),   
      }}
    >
      {props.children}
    </ErrorMessage>
  );
};

export const LabelContainer = styled.div({
  /* height: `calc(${style.large} + ${style.small})`, */
});

export const ErrorContainer = styled.div({
  /* minHeight: `calc(${style.large} + ${style.tiny})`, */
  // ...style.truncate(), // removed because we need to be able to show multiline error messages. RNB-987
});

export const inputStyle: Style = {
  // position is to fix selection of text when using the inline-complete component
  position: 'relative',
  display: 'inline-block',
  fontSize: 16,
  color: focusColor,
  width: '100%',
  height: '100%',
  cursor: 'text',
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  border: { xy: { width: 0 } },
  lineHeight: '100%',
  backgroundColor: ['transparent', 'initial'],
  // Remove default "required" border in FF
  ':required': {
    boxShadow: 'none',
  },
  paddingTop: 12,
  paddingBottom: 12,
  paddingLeft: 16,
  paddingRight: 16,
};

type BareInputPropType = StyledProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    elementRef?: React.Ref<HTMLInputElement>;
  };
export const BareInput = styled(
  (props: BareInputPropType) => {
    const { compose, elementRef, ...restProps } = props;
    return <styled.Input {...restProps} ref={elementRef} css={compose()} />;
  },
  {
    ...inputStyle,
  },
);

export const Wrapper = styled.span({
  position: 'relative',
});

type FieldStyleType = {
  borderStyle?: style.BorderStyle;
  disabled?: boolean;
  readOnly?: boolean;
  hasChild?: boolean;
  textArea?: boolean;
  focused?: boolean;
  invalid?: boolean;
};

const FieldWrap = styled.span({
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  cursor: 'text',
  maxWidth: '100%',
  backgroundColor: 'white',
})

const Field: React.StatelessComponent<FieldStyleType> = (props) => {
  const { focused, invalid, borderStyle = BorderStyle.GreyThinSolid } = props;
  const borderColor = borderStyle === BorderStyle.None ? 'transparent' : (borderStyle === BorderStyle.GreyThinSolid ? blurColor : style.colors.monochrome.black);
  const borderWidth = borderStyle === BorderStyle.None ? '1px' : (
    borderStyle === BorderStyle.GreyThinSolid ? 'thin' : borderStyle === BorderStyle.BlackThinSolid ? '1px' : '2px');
  const inputFocusColor = borderStyle === BorderStyle.None ? 'transparent' : focusColor;
  return (
    <FieldWrap
      css={{
        border: {
          xy: {
            width: borderWidth,
            style: 'solid',
            color: borderColor,
          },
          radius: 25,
        },
        ...(focused && {
          borderTopColor: inputFocusColor,
          borderBottomColor: inputFocusColor,
          borderLeftColor: inputFocusColor,
          borderRightColor: inputFocusColor,
        }),
        ...(invalid && {
          borderTopColor: errorColor,
          borderBottomColor: errorColor,
          borderLeftColor: errorColor,
          borderRightColor: errorColor,
        }),
        ...((props.disabled || props.readOnly) && {
          cursor: 'default',
          backgroundColor: style.colors.monochrome.extraLightGrey,
          border: {
            xy: {
              width: 'thin',
              style: 'solid',
              color: style.colors.monochrome.extraLightGrey,
            },
            radius: 25,
          },
        }),
        ...(props.disabled && {
          opacity: 0.75,
        }),
        ...(props.hasChild && {
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          // This is due to button element and input field has different font size
          height: '100%',
        }),
        ...(props.textArea && {
          height: 'inherit',
        }),
      }}
    >
      {props.children}
    </FieldWrap>
  );
};

const IconButton = styled(Button, {
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  lineHeight: 1,
  width: '48px',
});

type InputTextType =
  | 'date'
  | 'datetime'
  | 'datetime-local'
  | 'email'
  | 'month'
  | 'number'
  | 'password'
  | 'range'
  | 'search'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week';

export type PropType = React.InputHTMLAttributes<HTMLInputElement> & {
  type?: InputTextType;
  elementRef?: (el: HTMLInputElement) => void;
  ref?: any;
  label?: string;
  pattern?: string;
  placeholder?: string;
  errorText?: string;
  invalid?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  icon?: React.ComponentClass<any> | React.StatelessComponent<any>;
  forceShowErrorMessage?: boolean;
  onIconClick?: () => void;
  hideErrorMessage?: boolean;
  hideLabel?: boolean;
  hideRequiredIndicator?: boolean;
  borderStyle?: style.BorderStyle;
};

type Value = string | number | string[] | readonly string[];

type StateType = {
  focused?: boolean;
  hovered?: boolean;
  filled?: boolean;
  invalid?: boolean;
  edited?: boolean;
};

function isFilled(value: Value) {
  return !!value && (Array.isArray(value) ? value.length > 0 : /[\S]/.test(`${value}`));
}

function normalizeValue(value: string | number | string[] | readonly string[]) {
  return typeof value === 'number' ? value.toString() : value ? value.toString() : '';
}

export default styled(
  class Input extends React.Component<PropType & StyledProps, StateType> {
    element: HTMLInputElement;
    lastNewValueCaughtInField: string;

    constructor(props: PropType & StyledProps) {
      super(props);
      this.lastNewValueCaughtInField = normalizeValue(props.value);
      this.state = {
        edited: false,
        hovered: false,
        focused: false,
        filled: isFilled(props.value),
        invalid: false,
      };
    }

    componentWillReceiveProps(nextProps: PropType) {
      const nextvalue = normalizeValue(nextProps.value);
      if ((nextvalue && nextvalue !== this.lastNewValueCaughtInField) || nextProps.invalid !== this.props.invalid) {
        this.setState({
          filled: isFilled(nextvalue),
          edited: true,
          ...(nextProps.invalid !== undefined &&
            this.state.invalid !== nextProps.invalid && { invalid: nextProps.invalid }),
        });
      }
    }

    isInvalid(el: HTMLInputElement) {
      return this.props.invalid || !el.validity.valid;
    }

    onMouseEnter = (e: React.MouseEvent<HTMLInputElement>) => {
      this.setState({ hovered: true });
    };

    onMouseLeave = (e: React.MouseEvent<HTMLInputElement>) => {
      this.setState({ hovered: false });
    };

    onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      if (this.props.onFocus) {
        this.props.onFocus(e);
      }
      this.setState({ focused: true });
    };

    onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
      this.setState({
        focused: false,
        invalid: this.isInvalid(e.currentTarget),
      });
    };
    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const el = e.currentTarget;
      if (this.props.onChange) {
        this.props.onChange(e);
      }

      const valid = !this.isInvalid(el);
      // Only singal when an invlidated input becomes valid while typing (don't signal invalid until blurred)
      if (valid && valid === this.state.invalid) {
        this.setState({ invalid: false });
      }

      const filled = isFilled(el.value);
      if (filled !== this.state.filled) {
        this.setState({ filled });
      }
    };
    elementRef = (element: HTMLInputElement) => {
      if (this.props.elementRef) {
        this.props.elementRef(element);
      }
      this.element = element;
    };

    focus() {
      this.element.focus();
    }

    blur() {
      this.element.blur();
    }

    render() {
      const {
        label = '',
        value = '',
        size = 1,
        placeholder,
        children,
        compose,
        errorText,
        hideErrorMessage,
        hideLabel,
        disabled,
        style: styleProp,
        icon,
        forceShowErrorMessage,
        onIconClick,
        hideRequiredIndicator = true,
        invalid,
        borderStyle,
        ...restProps
      } = this.props;

      const realValue = value || '';
      const isInvalid = (this.state.filled || this.state.edited) && this.state.invalid;
      const hasIcon = icon !== undefined;
      const labelShown = !hideLabel && (this.state.focused || this.state.filled);

      return (
        <Wrapper css={compose()} style={styleProp}>
          {labelShown && (
            <Label>
              {label || placeholder}
              {this.props.required && !hideRequiredIndicator && ' *'}
            </Label>
          )}
          <Field
            borderStyle={borderStyle}
            disabled={disabled}
            focused={this.state.focused || this.state.hovered}
            invalid={isInvalid}
            hasChild={!!children}
          >
            <BareInput
              {...restProps}
              value={realValue}
              size={size}
              placeholder={!this.state.focused && placeholder ? placeholder : ''}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              data-invalid={isInvalid}
              elementRef={this.elementRef}
              css={{
                ...((labelShown || isInvalid) && {
                  paddingTop: 18,
                  paddingBottom: 6,
                }),
                border: {
                  radius: 25,
                },
              }}
            />
            {children}
            {hasIcon && (
              <IconButton onClick={onIconClick} appearance={[ButtonAppearance.Bare]}>
                <this.props.icon />
              </IconButton>
            )}
          </Field>
          {!hideErrorMessage && errorText && (isInvalid || forceShowErrorMessage) && (
            <ErrorContainer>
              <Label invalid>{errorText}</Label>
            </ErrorContainer>
          )}
        </Wrapper>
      );
    }
  },
);

type ReadonlyPropType = {
  label: string;
  children: React.ReactNode | React.ReactNode[];
};

const ReadonlyWrapperElement = styled.div({
  width: '100%',
});

export const Readonly: React.StatelessComponent<ReadonlyPropType> = ({ label, children }) => (
  <ReadonlyWrapperElement>
    <Label>{label}</Label>
    {children}
  </ReadonlyWrapperElement>
);

type ReadonlyWrapperPropType = {
  children: React.ReactNode | React.ReactNode[];
};

export const ReadonlyWrapper: React.StatelessComponent<ReadonlyWrapperPropType> = ({ children }) => (
  <ReadonlyWrapperElement>{children}</ReadonlyWrapperElement>
);

type AreaPropType = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  elementRef?: (el: HTMLTextAreaElement) => void;
  label?: string;
  pattern?: string;
  invalid?: boolean;
  errorText?: string;
  children?: React.ReactNode | React.ReactNode[];
  hideErrorMessage?: boolean;
  hideLabel?: boolean;
  hideRequiredIndicator?: boolean;
  borderStyle?: style.BorderStyle;
};

const InputTextArea = styled.textarea({
  ...inputStyle,
  resize: 'none',
  height: '175px',
  padding: { x: '16px', y: '12px'},
  fontSize: 16,
})

export const Area = styled(
  class extends React.Component<AreaPropType & StyledProps, StateType> {
    element: HTMLTextAreaElement;
    lastNewValueCaughtInField: string;

    constructor(props: AreaPropType & StyledProps) {
      super(props);
      this.lastNewValueCaughtInField = normalizeValue(props.value);
      this.state = {
        edited: false,
        hovered: false,
        focused: false,
        filled: isFilled(props.value),
        invalid: false,
      };
    }
    componentWillReceiveProps(nextProps: AreaPropType) {
      const nextvalue = normalizeValue(nextProps.value);
      if (nextvalue && nextvalue !== this.lastNewValueCaughtInField) {
        this.setState({
          filled: isFilled(nextvalue),
          edited: true,
          invalid: nextProps.invalid !== undefined ? nextProps.invalid : this.state.invalid,
        });
      }
    }
    isInvalid(el: HTMLTextAreaElement) {
      return this.props.invalid || !el.validity.valid;
    }

    onMouseEnter = (e: React.MouseEvent<HTMLInputElement>) => {
      this.setState({ hovered: true });
    };

    onMouseLeave = (e: React.MouseEvent<HTMLInputElement>) => {
      this.setState({ hovered: false });
    };

    onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      if (this.props.onFocus) {
        this.props.onFocus(e);
      }
      this.setState({ focused: true });
    };

    onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      if (this.props.onBlur) {
        this.props.onBlur(e);
      }
      this.setState({
        focused: false,
        invalid: this.isInvalid(e.currentTarget),
      });
    };
    onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const el = e.currentTarget;
      if (this.props.onChange) {
        this.props.onChange(e);
      }

      const valid = !this.isInvalid(el);
      // Only singal when an invlidated input becomes valid while typing (don't signal invalid until blurred)
      if (valid && valid === this.state.invalid) {
        this.setState({ invalid: false });
      }

      const filled = isFilled(el.value);
      if (filled !== this.state.filled) {
        this.setState({ filled });
      }
    };
    elementRef = (element: HTMLTextAreaElement) => {
      if (this.props.elementRef) {
        this.props.elementRef(element);
      }
      this.element = element;
    };

    focus() {
      this.element.focus();
    }

    blur() {
      this.element.blur();
    }

    render() {
      const {
        compose,
        label = '',
        value = '',
        placeholder,
        elementRef,
        className,
        errorText,
        hideLabel,
        hideErrorMessage,
        hideRequiredIndicator = true,
        style: styleProp,
        borderStyle,
        invalid,
        ...restProps
      } = this.props;

      const realValue = value || '';
      const isInvalid = (this.state.filled || this.state.edited) && this.state.invalid;
      const labelShown = !hideLabel && (this.state.focused || this.state.filled);

      return (
        <styled.Span
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          css={compose({
            position: 'relative',
          })}
        >
          {labelShown && (
            <LabelContainer>
              <Label>
                {label || placeholder}
                {this.props.required && !hideRequiredIndicator && ' *'}
              </Label>
            </LabelContainer>
          )}
          <Field focused={this.state.focused || this.state.hovered} invalid={isInvalid} textArea borderStyle={borderStyle}>
            <InputTextArea
              {...restProps}
              css={{
                ...((labelShown || isInvalid) && {
                  paddingTop: 18,
                  paddingBottom: 6,
                }),
              }}
              value={realValue}
              placeholder={!this.state.focused && placeholder}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onChange={this.onChange}
              ref={this.elementRef}
            />
          </Field>
          {!hideErrorMessage && (
            <ErrorContainer>{errorText && isInvalid && <Label invalid={isInvalid}>{errorText}</Label>}</ErrorContainer>
          )}
        </styled.Span>
      );
    }
  },
);
